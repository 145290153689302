import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpOrderEntity from "../entity";
import ErpOrderGeneral from "./show/general";
import ErpCustomerEntity from "../../customer/entity";
import UtilsErp from "../../../../utils/erp";

export default class ErpOrderNew extends DefaultController {
    orderData: any = {}
    private currentProductUuid: any = null
    private currentProductName: any = null


    async init() {
        this.entity = "erp/orders"
        this.childs = {
            general: new ErpOrderGeneral(this, "new")
        }
        await Utils.createSelects(this.data, (elem: any) => {
            return this.onSelectGetFilterOptions(elem)
        }, (data: any, entity: any, element: any) => {
            return this.onSelectFilterResults(data, entity, element)
        });

        this.bindListeners();
        this.childs.general.update({items: []})

        await this.checkParams()
        await Utils.hideLoader();
    }

    protected async addLineItem(entityData: any) {
        if (!this.orderData.items) {
            this.orderData.items = [];
        }
        const stockUuids = []
        for (const data of entityData) {
            console.log(data, "data")
            let existingIds = []
            let stockItem = this.orderData.items.filter(item => item.product.id === this.currentProductUuid)[0]
            let index = null;
            if (stockItem) {
                index = this.orderData.items.findIndex((obj: any) => obj.product.id === this.currentProductUuid)
                existingIds = stockItem.data.map(s => s.id)
            } else {
                stockItem = {
                    product: {
                        id: this.currentProductUuid,
                        name: this.currentProductName
                    },
                    data: []
                }
            }
            for (const serial of data.serials) {
                const stockData = await Utils.entity.find('erp/stocks', {
                    serial, warehouse_location_id: data.warehouse_location_id, warehouse_pallet_id: data.warehouse_pallet_id, product_id: this.currentProductUuid, ign_ids: existingIds
                })
                if (stockData.data) {
                    const existingStockItem = stockItem.data.filter((d: { id: any; }) => d.id === stockData.data.uuid)[0]
                    if (!existingStockItem) {
                        existingIds.push(stockData.data.uuid)
                        stockItem.data.push({
                            id: stockData.data.uuid,
                            serial: serial,
                            payload: stockData.data.payload
                        })
                        stockUuids.push(stockData.data.uuid)
                    } else {
                        console.warn("Stock exists already!", serial, data)
                    }
                } else {
                    console.warn("Stock not found!", serial, data)
                }
            }
            if (index !== null) {
                this.orderData.items[index] = stockItem
            } else {
                this.orderData.items.push(stockItem)
            }
        }
        await Utils.entity.upsert({reserved: true, uuid: stockUuids}, 'erp/stocks')
        // @ts-ignore
        await this.childs.general.update(this.orderData)
        if(this.orderData.items.length > 0) {
            (document.querySelector("#erp_order_save") as HTMLButtonElement).removeAttribute("disabled")
        } else {
            (document.querySelector("#erp_order_save") as HTMLButtonElement).setAttribute("disabled", "disabled")
        }
    }

    protected async addLinePallet(data: any, orderId = null) {
        if (!this.orderData.items) {
            this.orderData.items = [];
        }
        const stockUuids = []
        const pallet = await Utils.entity.get(data.warehousePalletId, 'erp/warehouse_location_pallets')
        if (pallet && pallet.status === 200) {
            const data = pallet.data[0]
            for (const stock of data.stock) {
                if (stock.available && !stock.reserved) {
                    let stockItem = this.orderData.items.filter((item: { product: { id: any; }; }) => item.product.id === stock.product.id)[0]
                    let index = null;
                    if (stockItem) {
                        index = this.orderData.items.findIndex((obj: any) => obj.product.id === stock.product.id)
                    } else {
                        stockItem = {
                            product: {
                                id: stock.product.id,
                                name: stock.product.name
                            },
                            data: []
                        }
                    }
                    const existingStockItem = stockItem.data.filter((d: { id: any; }) => d.id === stock.uuid)[0]
                    if (!existingStockItem) {
                        stockItem.data.push({
                            id: stock.uuid,
                            serial: stock.serial,
                            payload: stock.payload
                        })
                        stockUuids.push(stock.uuid)
                    } else {
                        console.warn("Stock exists already!", stock, stock)
                    }
                    if (index !== null) {
                        this.orderData.items[index] = stockItem
                    } else {
                        this.orderData.items.push(stockItem)
                    }
                }
            }
            await Utils.entity.upsert({reserved: true, uuid: stockUuids}, 'erp/stocks')
        }
        // @ts-ignore
        await this.childs.general.update(this.orderData)
        if(this.orderData.items.length > 0) {
            (document.querySelector("#erp_order_save") as HTMLButtonElement).removeAttribute("disabled")
        } else {
            (document.querySelector("#erp_order_save") as HTMLButtonElement).setAttribute("disabled", "disabled")
        }
    }

    protected async removeLineItem(ids: any) {
        const deletedIds = ids.split(",");
        const stockUuids = []
        let deleteIndex = null
        this.orderData.items.forEach((items: any, i: number) => {
            const stockIds = items.data.map((d: { id: any; }) => d.id);
            if (stockIds.sort().join(",") === deletedIds.sort().join(",")) {
                deleteIndex = i;
            }
        })
        if (deleteIndex !== null) {
            this.orderData.items.splice(deleteIndex, 1);
            for (const id of deletedIds) {
                stockUuids.push(id)
            }
        }
        // @ts-ignore
        await this.childs.general.update(this.orderData)
        await Utils.entity.upsert({reserved: false, uuid: stockUuids}, 'erp/stocks')
        if(this.orderData.items.length > 0) {
            (document.querySelector("#erp_order_save") as HTMLButtonElement).removeAttribute("disabled")
        } else {
            (document.querySelector("#erp_order_save") as HTMLButtonElement).setAttribute("disabled", "disabled")
        }
    }

    protected getEntityData(elem: any) {
        return ErpOrderEntity.getEntityData(elem)
    }

    bindListeners() {
        jQuery("#new_erp_order_line_item_product_id").on("select2:ajax_done", async (e, data: any) => {
            console.log("MAPDATA", data)
            if (data && data.status === 200 && data.data.length === 1 && data.data[0].is_serial_match === true) {
                const mapData = data.data[0]
                this.currentProductName = mapData.product.name
                this.currentProductUuid = mapData.product.id
                await this.addLineItem([{
                    quantity: 1,
                    serials: [mapData.serial],
                    warehouse_id: null,
                    warehouse_location_id: mapData.warehouse_location.id,
                    warehouse_pallet_id: mapData.warehouse_pallet ? mapData.warehouse_pallet.id : null
                }])
                const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddErpOrderLineItem") as HTMLElement))
                if (bsElem) {
                    bsElem.hide();
                }
                const elem = document.querySelector("#addErpOrderLineItemForm") as HTMLFormElement;
                jQuery("#new_erp_order_line_item_product_id").val("").trigger("change").html("");
                elem.reset();
            }
        });

        jQuery("#new_erp_order_line_item_product_id").on("select2:select", async (e, data: any) => {
            this.currentProductName = e.params.data.data.name
            this.currentProductUuid = e.params.data.data.uuid
            await this.addWarehouseInfo(e);
        });

        (document.querySelector("#erp_order_save") as HTMLButtonElement).addEventListener("click", async (e: any) => {

            await Utils.showLoader()
            const r = await Utils.entity.upsert(this.orderData, 'erp/orders')
            if (r.status === 200) {
                this.toastr.success(`${Utils.translate('erp.order.name')} ${Utils.translate('generic.messages.duplicated')}`, `${Utils.translate('generic.success')}`)
                setTimeout(() => {
                    document.location.href = `/${(window as any).currentLocale}/erp/orders/${r.data[0].id}`
                }, 1000)
            }
        });

        //@ts-ignore
        jQuery("#order_customer_id").on("select2:select", async (e: any) => {
            const customerData = e.params.data.data
            const addresses = customerData.customerLocations
            jQuery('#order_customer_address_id').empty().trigger('change');
            jQuery('#order_customer_address_id').append(new Option("Addresse wählen!", "NONE", false, false));
            addresses.forEach((address: any) => {
                    const newOption = new Option(address.name_formatted, address.uuid, false, false);
                    jQuery('#order_customer_address_id').append(newOption);
            })
            jQuery('#order_customer_address_id').trigger("change");
            jQuery('#order_customer_address_id').removeAttr("disabled")
            this.orderData.customer_id = customerData.uuid
        })

        //@ts-ignore
        jQuery("#order_customer_address_id").on("select2:select", async (e: any) => {
            const generalTab = document.querySelector(".erp_order_new_row #general") as HTMLElement;
            this.orderData.customer_location_id = e.target.value
            generalTab.style.opacity = "1";
            generalTab.style.pointerEvents = "all";
        })

        jQuery("body").delegate(".line_item_warehouse_map", "keyup change", (e) => {
            e.preventDefault();
            const target = e.target;
            const maxQuantity = parseInt(target.getAttribute("data-max-quantity"));
            const currentQuantity = parseInt(target.value);
            const fullQuantity = parseInt((document.querySelector("#erp_order_line_item_quantity") as HTMLInputElement).value);
            if (maxQuantity < currentQuantity) {
                target.value = String(maxQuantity);
            }
            let usedQuantity = 0;
            const inputs = document.querySelectorAll(".line_item_warehouse_map");
            inputs.forEach((input: any) => {
                usedQuantity += parseInt(input.value)
            })
            const saveBtn = document.querySelector("#erp_line_item_add_save_button") as HTMLButtonElement
            if (fullQuantity === usedQuantity) {
                saveBtn.removeAttribute("disabled");
            } else {
                saveBtn.setAttribute("disabled", "disabled");
            }
        })


        jQuery("body").delegate("#erp_order_line_item_quantity", "keyup change", (e) => {
            e.preventDefault();
            let usedQuantity = 0;
            const fullQuantity = parseInt((document.querySelector("#erp_order_line_item_quantity") as HTMLInputElement).value);
            document.querySelectorAll(".line_item_warehouse_map").forEach((input: any) => {
                const maxQuantity = parseInt(input.getAttribute("data-max-quantity"));
                const currentQuantity = parseInt(input.value);
                if (maxQuantity < currentQuantity) {
                    usedQuantity += maxQuantity
                } else {
                    usedQuantity += currentQuantity
                }
            })
            const saveBtn = document.querySelector("#erp_line_item_add_save_button") as HTMLButtonElement
            if (fullQuantity === usedQuantity) {
                saveBtn.removeAttribute("disabled");
            } else {
                saveBtn.setAttribute("disabled", "disabled");
            }
        })
    }

    async addWarehouseInfo(data: any) {
        if (data.params.data.id) {
            console.log(this.data, data)
            const stockInfo = await Utils.entity.getAll('erp/stocks', null, {product_id: data.params.data.id, customer_id: this.orderData.customer_id});
            let html = '';
            const tbody = document.querySelector("#erp_order_line_item_add_warehouse_list_table tbody") as HTMLElement;
            tbody.innerHTML = "";
            stockInfo.data.forEach((stock: any) => {
                let name = `${stock.warehouse.name}, ${stock.warehouse_location.name}`
                if (stock.warehouse_pallet) {
                    name += `, ${stock.warehouse_pallet.name}`
                }
                let options = ``
                stock.serials.filter(n => n).forEach((sn: string) => {
                    options += `<option value='${sn}'>${sn}</option>`
                })
                let availableStock = stock.stock
                if (availableStock > 0) {

                    html += `<tr>
<td style="width: 100%;">
<div class="row align-items-center">
 <div class="col-12 mb-1" style="font-size:12px;">${name}</div>
 <div class="col-6 mb-1">
 <input class="form-control line_item_warehouse_map" value="0" type="number" min="0" max="${availableStock}" data-max-quantity="${availableStock}" data-warehouse-id="${stock.warehouse.uuid}" data-warehouse-location-id="${stock.warehouse_location.uuid}" ${stock.warehouse_pallet ? `data-warehouse-pallet-id="${stock.warehouse_pallet.uuid}"` : ``} />

</div>
 <div class="col-6 mb-1"> / ${availableStock}</div>
 <div class="col-12">
 <label class="form-label">Serials: </label>
 <select class="form-select" multiple>
 ${options}
</select>
 </div>
</td>
</tr>`
                }
            })
            tbody.innerHTML = html;
            console.log("Stock Info", stockInfo)
        }
    }

    async checkParams() {
        const uri = new URL(document.location.href)
        const params = uri.searchParams

        if (params.get("ticket_id")) {
            this.orderData.ticket_id = (params.get("ticket_id") || "");
            (document.querySelector("#erp_order_ticket_uuid") as HTMLInputElement).value = params.get("ticket_id") || ""
        }
        if (params.get("customer_id")) {
            this.orderData.customer_id = (params.get("customer_id") as string)
            const customer = await Utils.entity.get(this.orderData.customer_id, "customers");
            const $newOptionCustomer = jQuery("<option selected='selected'></option>").val(customer.data[0].uuid).text(customer.data[0].name)
            jQuery("#order_customer_id").append($newOptionCustomer).trigger('change')
            jQuery('#order_customer_address_id').removeAttr("disabled")
            if (params.get("customer_location_id")) {
                this.orderData.customer_location_id = (params.get("customer_location_id") as string)
                const locations = customer.data[0].customerLocations
                jQuery('#order_customer_address_id').empty().trigger('change');
                locations.forEach((address: any) => {
                    const newOption = new Option(address.name_formatted, address.uuid, this.orderData.customer_location_id === address.uuid, this.orderData.customer_location_id === address.uuid);
                    jQuery('#order_customer_address_id').append(newOption);
                })
                jQuery('#order_customer_address_id').trigger("change");
                jQuery('#order_customer_address_id').removeAttr("disabled");
                const generalTab = document.querySelector(".erp_order_new_row #general") as HTMLElement;
                generalTab.style.opacity = "1";
                generalTab.style.pointerEvents = "all";
            } else {
                //@ts-ignore
                jQuery("#order_customer_id").trigger({
                    type: 'select2:select',
                    params: {
                        data: {
                            data: customer.data[0]
                        }
                    }
                });
            }
        }
    }
    onSelectGetFilterOptions(elem: any) {
        console.log("eh", elem, this.orderData)
        if (elem.getAttribute("id") === "new_erp_order_line_item_product_id") {
            return {
                customer_id: this.orderData.customer_id
            }
        } else if (elem.getAttribute("id") === "erp_pallet_warehouse_location_pallet_id") {
            return {
                warehouse_id: (document.querySelector("#erp_pallet_warehouse_id option:checked") as HTMLSelectElement)?.value
            }
        } else if (elem.getAttribute("id") === "order_customer_id") {
            return {
                active: true
            }
        }
        return {}
    }
}